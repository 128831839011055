import { Roles } from './roles'

export interface Route {
    path: string
    roles: string[]
}

export const routes: { [key: string]: Route } = {
    index: {
        path: '/',
        roles: [],
    },
    signIn: {
        path: '/auth/inloggen',
        roles: [],
    },
    register: { path: '/auth/aanmelden', roles: [] },
    trouble: { path: '/auth/wachtwoord-vergeten', roles: [] },
    reset: { path: '/auth/reset', roles: [] },
    profile: { path: '/profiel', roles: [Roles.ROLE_USER] },
    account: { path: '/account', roles: [Roles.ROLE_USER] },
    favorites: { path: '/favorites', roles: [Roles.ROLE_USER] },
    terms: { path: '/terms', roles: [] },
    organisations: { path: '/admin/organisations', roles: [Roles.ROLE_APPLICATION_ADMIN] },
    organisationsDetail: { path: '/admin/organisations/[organisationId]', roles: [Roles.ROLE_APPLICATION_ADMIN] },
    organisationsCreate: { path: '/admin/organisations/create', roles: [Roles.ROLE_APPLICATION_ADMIN] },
    myOrganisation: { path: '/admin/my-organisation', roles: [Roles.ROLE_ORGANISATION_ADMIN] },
    users: { path: '/admin/users', roles: [Roles.ROLE_ORGANISATION_ADMIN] },
    userDetail: { path: '/admin/users/[userId]', roles: [Roles.ROLE_ORGANISATION_ADMIN] },

    topics: { path: '/admin/topics', roles: [Roles.ROLE_ORGANISATION_ADMIN] },
    topicsDetail: {
        path: '/admin/topics/[topicId]',
        roles: [Roles.ROLE_ORGANISATION_ADMIN, Roles.ROLE_APPLICATION_ADMIN],
    },
    topicsCreate: {
        path: '/admin/topics/create',
        roles: [Roles.ROLE_ORGANISATION_ADMIN, Roles.ROLE_APPLICATION_ADMIN],
    },

    trainingProviders: { path: '/admin/trainingProviders', roles: [Roles.ROLE_MANAGE_TRAINING_PROVIDER] },
    trainingProvidersDetail: {
        path: '/admin/trainingProviders/[trainingProviderId]',
        roles: [Roles.ROLE_MANAGE_TRAINING_PROVIDER],
    },
    trainingProvidersCreate: { path: '/admin/trainingProviders/create', roles: [Roles.ROLE_MANAGE_TRAINING_PROVIDER] },

    toolProviders: { path: '/admin/lti-toolproviders', roles: [Roles.ROLE_APPLICATION_ADMIN] },
    toolProvidersDetail: {
        path: '/admin/lti-toolproviders/[toolProviderId]',
        roles: [Roles.ROLE_APPLICATION_ADMIN],
    },
    toolProvidersCreate: { path: '/admin/lti-toolproviders/create', roles: [Roles.ROLE_APPLICATION_ADMIN] },

    trainingsAdmin: { path: '/admin/trainings', roles: [Roles.ROLE_ORGANISATION_ADMIN] },
    trainingAdminDetail: { path: '/admin/trainings/[trainingId]', roles: [Roles.ROLE_ORGANISATION_ADMIN] },
    trainingAdminCreate: { path: '/admin/trainings/create', roles: [Roles.ROLE_ORGANISATION_ADMIN] },

    questionnairesAdmin: { path: '/admin/questionnaires', roles: [Roles.ROLE_ORGANISATION_ADMIN] },
    questionnairesAdminDetail: {
        path: '/admin/questionnaires/[questionnaireId]',
        roles: [Roles.ROLE_ORGANISATION_ADMIN],
    },
    questionnairesAdminCreate: { path: '/admin/questionnaires/create', roles: [Roles.ROLE_ORGANISATION_ADMIN] },

    startPageManagement: { path: '/admin/startpage-management', roles: [Roles.ROLE_ORGANISATION_ADMIN] },
    startPageManagementDetail: {
        path: '/admin/startpage-management/[categoryId]',
        roles: [Roles.ROLE_ORGANISATION_ADMIN],
    },
    startPageManagementCreate: {
        path: '/admin/startpage-management/create-category',
        roles: [Roles.ROLE_ORGANISATION_ADMIN],
    },

    shorts: { path: '/admin/shorts', roles: [Roles.ROLE_ORGANISATION_ADMIN] },
    shortsDetail: { path: '/admin/shorts/[shortsId]', roles: [Roles.ROLE_ORGANISATION_ADMIN] },
    shortsCreate: { path: '/admin/shorts/create', roles: [Roles.ROLE_ORGANISATION_ADMIN] },
    shortsOverview: { path: '/shorts', roles: [] },

    skills: { path: '/admin/skills', roles: [Roles.ROLE_APPLICATION_ADMIN] },
    skillsCreate: { path: '/admin/skills/create', roles: [Roles.ROLE_APPLICATION_ADMIN] },

    search: { path: '/search', roles: [] },
    trainings: { path: '/opleidingsaanbod', roles: [] },
    trainingDetail: { path: '/opleidingsaanbod/[trainingId]', roles: [] },

    tests: { path: '/testen', roles: [] },
    test: { path: '/testen/[questionnaireId]', roles: [Roles.ROLE_USER] },

    categories: { path: '/categorie', roles: [] },
    categoryDetail: {
        path: '/categorie/[categoryId]',
        roles: [],
    },
    providers: { path: '/aanbieders', roles: [] },
    adminDashboard: { path: '/admin/dashboard', roles: [Roles.ROLE_APPLICATION_ADMIN, Roles.ROLE_ORGANISATION_ADMIN] },
    adminSigbar: { path: '/admin/sigbar', roles: [Roles.ROLE_APPLICATION_ADMIN, Roles.ROLE_ORGANISATION_ADMIN] },
    adminLogin: { path: '/auth/admin/inloggen', roles: [] },
}
